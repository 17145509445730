<template>
  <b-row>
    <b-col sm="12" md="8">
      <my-card collapsed title="Sortir" :badge="$store.getters['stockins/isFiltered'] ? 'Filtered':''">
        <template slot="rawtitle">
          <feather-icon icon="XIcon"></feather-icon>
        </template>
        <template slot="body">
          <form @submit.prevent="submitFilter">
            <b-row>
              <b-col sm="12">
                <form-select-new :filter-id="idBranchArray" url="v1/branch_select2" :queryparams="{id_regional: mainRegionalId}" v-model="filter.branch" label="Cabang Asal"></form-select-new>
              </b-col>
              <b-col sm="12">
                <b-form-group label-for="radio-group-type">
                  <b-form-radio-group
                  id="radio-group-type"
                  v-model="filter.type"
                  :options="utils.types"
                  text-field="label"
                  >
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col sm="12" v-show="filter.type==1">
                <form-select-new url="v1/branch_select2" v-model="filter.branch_sender" label="Cabang Tujuan"></form-select-new>
              </b-col>
              <b-col sm="12" v-show="filter.type==2">
                <form-select-new url="v1/supplier_select2" v-model="filter.supplier" label="Pemasok"></form-select-new>
              </b-col>
              <b-col sm="6">
                <form-date :max="maxDate" v-model="filter.date_1" label="Tanggal Mulai"></form-date>
              </b-col>
              <b-col sm="6">
                <form-date :min="minDate" v-model="filter.date_2" label="Tanggal Selesai"></form-date>
              </b-col>
              <b-col sm="12">
                <form-select-static :options="utils.statuses" v-model="filter.status" label="Status"></form-select-static>
              </b-col>
              <b-col sm="12">
                <button-action size="sm" @clicked="submitFilter" name="Filter" featherIcon="FilterIcon"></button-action>
                <button-action size="sm" @clicked="resetFilter" name="Reset" variant="warning"></button-action>
                <button-action v-if="cP(131)" size="sm" @clicked="exportExcel" variant="warning" name="Export"></button-action>
              </b-col>
            </b-row>
          </form>
        </template>
      </my-card>
    </b-col>
    <b-col sm="12">
      <my-card title="Daftar Permintaan Stok Masuk">
        <template slot="button">
          <b-button v-if="cP(139)" @click="getMarkup" variant="primary">Set Qty Ref</b-button>
          <button-add v-if="cP(81) && !isPusat" :to="{name: 'stockins-add'}" variant="primary"></button-add>
        </template>
        <template slot="body">
          <data-tables sort-key="4" desc ref="datatables" endpoint="v1/stock_in_requests" :query-params="filterQuery" :fields="fields">
            <template v-slot:4="{item}">
              {{item[4] | mindate}}
            </template>
            <template v-slot:6="{item}">
              {{item[6] | formatNumber}}
            </template>
            <template v-slot:7="{item}">
              <div class="d-flex justify-content-end">
                <button-link size="sm" :to="{name:'stockins-show',params:{id: item[7]}}" feather-icon="FolderIcon"></button-link>
                <button-link v-if="approveApply(item)" variant="success" size="sm" :to="{name:'stockins-approval',params:{id: item[7]}}" feather-icon="CheckIcon"></button-link>
                <button-link v-if="cP(84) && !isPusat && item[5]=='Permintaan'" size="sm" :to="{name:'stockins-edit',params:{id: item[7]}}" feather-icon="EditIcon"></button-link>
                <button-delete v-if="cP(85) && !isPusat && item[5]=='Permintaan'" @ondelete="deleteRow" :id="item[7]" :name="`${item[0]} (${item[2]})`"></button-delete>
              </div>
            </template>
          </data-tables>
        </template>
      </my-card>
      <b-modal ref="markupModal" title="Setting Qty Ref Permintaan" hide-footer centered size="lg">
        <p class="font-weight-bolder">
          Qty Ref = [ (AVG * Umur Stok) * Markup ] - [ Stok Akhir - (Lead Time * AVG) ]
        </p>
        <span>Keterangan :</span>
        <ol>
          <li>AVG : Stok keluar rata-rata di 7 hari sebelum tgl permintaan.</li>
          <li>Umur Stok : Default di set 7 hari.</li>
          <li>Markup : Parameter kompensasi selisih qty permintaan seharusnya.</li>
          <li>Stok Akhir : Posisi stok sekarang pada item terkait.</li>
          <li>Lead Time : Selisih hari antara tanggal kebutuhan dengan tanggal permintaan.</li>
        </ol>
        <form-validation @submit="submitMarkup">
          <form-input-number :rules="{required: true}" v-model="markup" label="Mark Up"></form-input-number>
          <b-button type="submit" variant="primary"><feather-icon icon="SaveIcon"></feather-icon> Simpan</b-button>
        </form-validation>
      </b-modal>
    </b-col>
  </b-row>
</template>

<script>
import ButtonAdd from '@/my-components/ButtonAdd.vue'
import ButtonAction from '@/my-components/ButtonAction.vue'
import DataTables from '@/my-components/DataTables.vue'
import ButtonDelete from '@/my-components/ButtonDelete.vue'
import ButtonLink from '@/my-components/ButtonLink.vue'
import FormSelectNew from '@/my-components/FormSelectNew'
import FormSelectStatic from '@/my-components/FormSelectStatic'
import FormDate from '@/my-components/FormDate'
import { mapFields } from 'vuex-map-fields'
import {BFormGroup,BFormRadioGroup,BModal,BButton} from 'bootstrap-vue'
import utils from './utils'
import _ from 'lodash'
import FileSaver from 'file-saver'
import FormValidation from '@/my-components/FormValidation.vue'
import FormInputNumber from '@/my-components/FormInputNumber.vue'

export default {
  components:{
    ButtonAdd,
    DataTables,
    ButtonDelete,
    ButtonLink,
    FormDate,
    ButtonAction,
    BFormGroup,
    BFormRadioGroup,
    FormSelectStatic,
    FormSelectNew,
    BModal,
    FormValidation,
    FormInputNumber,
    BButton
  },
  data() {
    return {
      utils:utils,
      fields: {
        0:{sortable: true, searchable: true, label:'Kode'},
        1:{sortable: true, searchable: true, label:'Tipe'},
        2:{sortable: true, searchable: true, label:'Cabang Asal'},
        3:{sortable: true, searchable: true, label:'Cabang Tujuan/Pemasok'},
        4:{sortable: true, searchable: true, label:'Tgl Permintaan'},
        5:{sortable: true, searchable: true, label:'Status'},
        6:{sortable: true, searchable: true, label:'Total'},
        7:{sortable: false, searchable: false, label:''},
      },
      markup:null
    }
  },
  computed:{
    ...mapFields('stockins',['filter']),
    filterQuery(){
      let filters = JSON.parse(JSON.stringify(this.filter))
      if(filters.branch) filters.id_branch = filters.branch.value
      if(filters.branch_sender) filters.id_branch_sender = filters.branch_sender.value
      if(filters.supplier) filters.id_supplier = filters.supplier.value
      delete filters.branch
      delete filters.branch_sender
      delete filters.supplier
      return filters
    },
    userProfile(){
      return this.$store.state.auth.profile
    },
    minDate(){
      const min = this.filter.date_1
      if(!min) return null
      else return this.$moment(min).format()
    },
    maxDate(){
      const max = this.filter.date_2
      if(!max) return null
      else return this.$moment(max).format()
    },
  },
  methods:{
    approveApply(item){
      if(this.cP(83)){
        if(this.isPusat||this.isRegional){
          if(item[1]==="Transfer In"){
            return _.includes(["Permintaan","Disetujui Pusat"],item[5])
          } else {
            if(item[5]==="Permintaan") return true;
            else return false
          }
        }
        else return false
      } else {
        return false
      }
    },
    firstInit(){
      if(!this.isPusat) {
        if(!this.filter.branch)
        this.filter.branch = {value:this.userProfile.id_branch,label:this.userProfile.branch_name}
      }
    },
    submitFilter(){
      this.$refs.datatables.refresh()
    },
    resetFilter(){
      this.filter.type = ""
      if(this.isPusat){
        this.filter.id_branch = ""
        this.filter.branch = null
      }
      this.filter.id_branch_sender = ""
      this.filter.branch_sender = null
      this.filter.id_supplier = ""
      this.filter.supplier = null
      this.filter.date_1 = ""
      this.filter.date_2 = ""
      this.filter.status = ""
      this.$refs.datatables.refresh()
    },
    async deleteRow(payload){
      try {
        await this.$store.dispatch('stockins/destroy',payload)
        this.$refs.datatables.refresh()
        this.toastSuccess("OK","Data Berhasil Dihapus!")
      } catch (error) {
        this.handleError(error)
      }
    },
    async exportExcel(){
      const filters = this.filterQuery
      try {
        const res = await this.$http.get(`v1/stock_in_request_export`,{params:filters,responseType: 'blob'})
        FileSaver.saveAs(res.data,'PERMINTAN STOK MASUK')
      } catch (error) {
        this.handleError(error)
      }
    },
    async getMarkup(){
      try {
        const {data} = await this.$http.get(`v1/get_general_setting`,{params:{id:1}})
        this.markup = data.value
        this.$refs.markupModal.show()
      } catch(error){
        this.handleError(error)
      }
    },
    async submitMarkup(){
      try {
        const params = {
         "type": 1,
         "value" : this.markup
        }
        await this.$http.put(`v1/general_setting_edit`,params)
        this.toastSuccess("OK","Data Berhasil Disimpan!")
        this.$refs.markupModal.hide()
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  created(){
    this.firstInit()
  }
}
</script>

<style>

</style>